@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,700|Roboto:400,900,300);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,700);
/**
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Lora', serif;
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,700,900|Lora:400,400italic,700,700italic);
font-family: 'Libre Baskerville', serif;
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,700|Roboto:400,900,300);
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,700);
font-family: 'Crimson Text', serif;
**/

/**
============================
Site-wide Styles 
============================
**/
p.address-line:first-child {
    margin-left: 0 !important;
}
p.address-line {
    margin: 0px 40px 50px 0 !important;
    display: inline-block
}
.white {
    color: #fff;
}
.none {
    display: none;
}
html, html a {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
a:hover {
    text-decoration: none;
}

p, li, blockquote {
   font-family: 'Roboto', sans-serif;
   font-size: 14px;
}

blockquote {
    line-height: 35px;
}
h1 {
    text-transform: uppercase;
}
h1, h2, h3, h4, h5 {
    font-family: 'Crimson Text', serif;
    font-weight: 300;
}
a {
    text-decoration: none;
    color: #212121;
}

.btn-primary {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    padding: 10px 50px;
    color: #212121;
    font-size: 14px;
}

.btn-yellow.btn-primary[disabled]:hover {
    background: #ead67b;
    cursor: not-allowed;
}

.btn-yellow {
    background: #ead67b;
    color: white;
}

.btn-yellow:hover {
    background: #ead67b;
}
.btn-yellow:focus {
    background: #ead67b;
}

.btn-blue {
    background: #1D3B6D;
    color: white;
}

.btn-blue:hover {
    background: #1D3B6D;
}

.btn-blue:focus {
    background: #1D3B6D;
}

h1 {
    font-size: 44px;
    color: #212121;
}
.secondaryhero {
    height: 500px;
    background-size: cover !important;
    padding: 100px 0;
    margin-top: -160px;
    background-attachment: fixed !important;
}

.secondaryhero h1 {
    margin-top: 200px;
    color: white;
    font-size: 54px;
}
.secondary-section {
    padding: 50px 0;
}

.secondary-section .container {
    width: 75%;
}

.secondary-section p {
    line-height: 35px;
}

.secondary-section h4 {
    margin-top: 25px;
}
/**
============================
Navbar
============================
**/


.navbar-top {
    height: 110px;
}

.navbar-header {
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    
}
.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar .navbar-collapse {
  text-align: center;
}

.navbar-header {
    padding-left: 0;
}

.navbar-top {
    position: relative;
    background: rgba(255,255,255,0.7);
    z-index: 10;
}

.navbar-top .container-fluid {
    padding-left: 0;
}

.navbar-top .tagline .under {
    color: #212121;
    font-style: italic; 
    font-weight: 400;
}
.navbar-top img {
    height: 100px;
    position: relative;
    top: 5px;
    right: 9px;
}

.navbar-default {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    height: 50px;
    margin-bottom: 0;
}

.navbar-default .container-fluid {
    padding-right: 0px;
}
.navbar-nav {
    width: 100%;
    padding-left: 11%;
}

.navbar-nav .fa {
    font-size: 18px;
}

.navbar-collapse {
    padding-right: 0px;
}

.navbar-nav .btn-primary {
    float: right;
    height: 50px;
    padding-right: 50px;
    padding-left: 50px;
    border: none;
    background: transparent;
}

.navbar-nav .btn-primary .fa {
    margin: 0 12px;
    font-size: 20px;
}

.navbar-nav li a {
    color: #212121 !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}

.nav>li {
    margin-right: 4%;
}
.navbar .navbar-brand {
    display: none;
}

.dropdown-menu {
    width: 200px;
    border-radius: 0;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, 
.navbar-default .navbar-nav>.open>a:hover {
    background: transparent;
}

.dropdown-menu li {
    padding: 5px 0;
} 

/**
============================
Navbar 2
============================
**/




/**
============================
Footer
============================
**/
.footer {
    min-height: 330px;
    width: 100%;
    background:#212121;
    padding-top: 60px;
    color: #9E9E9E;
}

.footer img {
    height: 100px;
    width: auto;
    margin-top: 28px;
}

.footer .footer-list {
    padding-left: 0;
    list-style-type: none;
    
}

.footer .footer-list li {
    padding: 5px 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.footer-list li a {
    color: #9E9E9E;
}

.footer h3 {
    margin-bottom: 20px;
}

.footer .fa {
    font-size: 24px;
    padding-right: 10px;
    color: #9E9E9E;
}

.footer-rights {
    padding: 15px 0 10px 0;
    margin-bottom: 0;
    margin-top: 40px;
    width: 100%;
    background: #141414;
}


/**
============================
Home
============================
**/
.hero {
    background: url('/images/about-hero.jpg');
    min-height: 800px;
    background-size: cover;
    padding: 100px 0;
    margin-top: -160px;
    background-attachment: fixed;
}

.hero h1 {
    font-family: 'Crimson Text', serif;;
    color: #eeeeee;
    font-weight: 400;
    font-size: 62px;
    margin-top: 205px;
    margin-left: 25px;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
}

.hero h2 {
    font-size: 42px;
    color: white;
    margin-top: 0;
}

.hero p {
    color: #eeeeee;
    font-size: 22px;
    width: 60%;
    line-height: 40px;
    margin: 0 auto;
    text-shadow: 1px 1px 1px rgba(0,0,0,1);
    
}

.hero .btn-yellow {
    margin: 25px 0 0 28px;
    background: #ead67b;
    margin-right: 15px;
    color: white;
    
}

.hero .btn-yellow:hover {
    background: #F3E7B2;
}



.service-bar {
    min-height: 400px;
    padding-bottom: 30px;
}
.service-bar .col-md-6 h3 {
    font-size: 20px;
}
.service-bar .col-md-6 {
    padding-top: 30px;
}
.service-bar .col-md-6 h2 {
    margin-top: 0;
}

.service-bar .building-text {
    width: 70%;
    margin: 0 auto;
}

.service-bar .service-bar-l

.service-bar .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
    text-align: center;
}

.service-bar .list-inline>li {
    margin: 15px;
}


.service-bar .icon-circle {
    text-align: center;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 35px;
    border: 2px solid #2b56a1;
    padding-top: 11px;
}

.service-bar .icon-circle .icon {
    font-size: 42px;
    color: #2b56a1;
}
.col-lg-6 .list-inline li h3 {
    font-size: 20px;
}
.service-bar .service-bar-list {
    width: 40%;
    margin: 20px auto;
}

.service-bar-list li {
    margin-top: 20px;
}

.btn-lightblue {
    background: #2b56a1;
    color: white;
}

.services {
    padding: 15px 0 25px 0;
    background: #212121;
    color: white;
}

.services h2 {
    margin-bottom: 40px;
}

.services .btn-primary {
    margin-bottom: 20px;
}

.services .row {
    text-align: center;
}

.services .row p {
    line-height: 30px;
    margin: 15px 0;
    
}


section.parallax {
    background: url('/images/framing-schome.jpg') center;
    padding: 350px 0;
    background-attachment: fixed;
    background-size: cover;
}

.parallax h1 {
    color: white;
    font-size: 62px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
}
section.parallax2 {
    background: url('/images/2_schome.jpeg') center left;
    padding: 350px 0;
    background-attachment: fixed;
    background-size: cover;
}

.parallax2 h1 {
    color: white;
    font-size: 62px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
}

.area .container-fluid {
    padding-left: 0px;
}
.map-container {
    width: 100%;
    height: 400px;
}

.map-container #map {
    width: 100%;
    height: 100%;
    background: url('/images/home-map.png');
    background-size: cover;
}

.area .area-list {
    margin: 30px auto;
    width: 50%;
    position: relative;
    left: 35px;
}

.area .area-list li {
    margin-top: 20px;
}

.area .area-right {
    padding-top: 30px;
}

.area .area-right .btn-primary {
    margin: 20px auto 0 auto;
    background: #1D3B6D;
    color: #fff;
}

.area .col-md-6 {
    padding-right: 0;
}

.contact-bar {
    padding: 30px 0 60px 0;
    background: #424242;
}
.contact-bar form {
    margin: 0 auto;
    text-align: center;
    width: 70%;
}

.contact-bar .form-control {
    height: 40px;
    border-radius: 0;
    width: 300px;
    display: inline;
    border: none;
    padding-left: 30px;
}

.contact-bar .btn-primary {
    display: inline;
    height: 40px;
    position: relative;
    right: 10px;
    border-radius: 0;
}

.contact-bar .input-group {
    margin: 20px auto;
    padding-left: 15px;
}

.contact-bar h2 {
    color: white;
}


.abc {
    display: none;
}
/**
============================
About 
============================
**/

.about-hero {
    background: url('/images/bg7.jpg');
}

.about-section p {
    margin-bottom: 50px;
}


/**
============================
Areas of Service
============================
**/

.memberships-hero {
    background: url('/images/areasofservice.jpg');
}
/**
============================
memberships
============================
**/
.memberships-section .list-vertical {
    margin-bottom: 60px;
}
.memberships-section .list-vertical li {
    margin: 20px 0;
}

/**
============================
Partnerships
============================
**/
.partnerships-hero {
    background: url('/images/partnerships.jpg') center bottom;
}
.partnerships-section img {
    height: 150px;
    width: auto;
    margin: 15px 0;
}

.meridian img {
    height: 130px;
    width: auto;
    margin-bottom: 37px;
}

.recommend-list .lowercase {
    text-transform: lowercase !important;
}


.recommend-list {
    list-style-type: none;
    text-transform: uppercase;
    margin-bottom: 30px;
    padding-left: 0;
}

/**
============================
Building Services
============================
**/

.building-services-hero {
    background: url('/images/buildingservices.jpg');
}

.building-services-list li {
    margin: 20px 0;
}

/**
============================
custom homes
============================
**/

.custom-homes-hero {
    background: url('/images/customhome-hero.jpg') center center;
}

.case-study-images img:first-child {
    height: 250px;
    width: 250px;
    margin: 20px 6% 20px 0px;
}

.case-study-images img {
    height: 250px;
    width: 205px;
    margin: 20px 10px;
}

.case-study {
    margin: 40px 0;
}

/**
============================
Testimonials
============================
**/

.testimonials-hero{
    background: url('/images/testimonials.jpg');
}

.testimonials-section {
    padding-bottom: 80px;
}

.testimonials-section h2 {
    margin-bottom: 50px;
}

.testimonials-section blockquote {
    margin-bottom: 25px;
}


/**
============================
Contact
============================
**/

#contactmap {
    height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    background:#333;
}

.contact-hero h1 {
    position: relative;
    z-index: 10;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);;
}

.contact-section .container {
    width: 70%;
}
.contact-section input {
    border-radius: 0;
    outline: 0;
}
.contact-section textarea.form-control {
    border-radius: 0;
    outline: 0;
} 
.contact-section p {
    margin: 30px 0;
}


.message-hero {
    background: #00E676;
}

.fvalid, .finvalid {
    position: absolute;
    left: 25px;
    top: 11px;
    z-index: 999;
}
.finvalid {
    color: red;
}
.fvalid {
    color: #00E676;
}
@media only screen and (min-width: 1028px) {
    .justified {
        position: relative;
        right: 15px;
    }
    
}
@media only screen and (max-width: 1058px){
    .nav>li {
        margin-right: 2.5%;
    }
    .nav>li>a {
        font-size: 12px;
    }
    .nav>li.right {
        margin-right: 1.8%;
    }
}
@media only screen and (max-width: 960px) {
    .footer .row {
        width: 100%;
    }
    .footer .row .col-md-3 {
        text-align: center;
    }
    .footer .list-inline {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 900px){
    .nav>li {
        margin-right: 2%;
    }
    .nav>li>a {
        font-size: 10px;
    }
    .nav>li.right {
        margin-right: 1.4%;
    }
}

@media only screen and (max-width : 768px) {
    .areas-of-service-hero {
        background: url('/images/areasofservice-medium.jpg') right;
    }
    .building-services-hero {
        background: url('/images/buildingservices-medium.jpg') right;
    }
    .partnerships-hero {
        background: url('/images/partnerships-medium.jpg') center bottom;
    }
    .case-studies-hero {
        background: url('/images/casestudies-medium.jpg') center bottom;
    }
    .navbar-header {
        background: rgba(255,255,255,0.7);
    }
    .navbar-collapse {
        background: rgba(255,255,255,0.7);
        margin-top: 100px;
        border:none;
    }
    .navbar-nav {
        padding-left: 0;
    }
    
    .nav>li>a {
        font-size: 12px;
    }
    .dropdown-menu {
        text-align: center;
    }
    .navbar-top {
        display: none;
    }
    .navbar-header {
        height: 100px;
        background: rgba(255,255,255,0.7);
    }
    button.navbar-toggle {
        border: none;
        position: absolute;
        top: 20px;
        right: 10px;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background: transparent;
    }
    .navbar-brand {
        display: block;
        width: 100%;
        height: 0px;
        padding: 0px;
        position: relative;
    }
    .navbar-brand img {
        display: block;
        height: 75px;
        width: auto;
        margin: 12px auto 0 auto;
    }
    .navbar {
        background: transparent;
    }
    .navbar .navbar-brand {
        display: block;
    }
    .fa-bars {
        font-size: 30px;
    }
    .contact-bar form {
        width: 100%;
    }
    .footer .row {
        width: 100%;
    }
    .footer .row .col-md-3 {
        text-align: center;
    }
    
    
    .hero h1 {
        font-size: 42px;
    }
    
    .parallax h1 {
        font-size: 42px;
    }
    
    .parallax2 h1 {
        font-size: 42px;
    }
    
    .secondaryhero h1 {
        font-size: 42px;
    }
    
    .hero p {
        width: 70%;
    }
    
    .hero p.buttons {
        width: 100%;
    }
    
    .hero .btn-primary {
        margin-top: 0;
        margin-bottom: 0;
        padding: 5px 25px;
    }

}

@media only screen and (max-width : 480px) {
    .contact-bar .form-control {
        width: 200px;
    }
    .hero {
        background: url('/images/about-hero-mobile.jpg') 0 50%;
        min-height: 0;
        padding: 100px 0;
        margin-top: -160px;
        -webkit-background-size: auto 100%;
        background-attachment: scroll;
        background-size: cover;
    }
    section.parallax {
        background: url('/images/framing-schome.jpg') 0 50%;
        background-size: cover;
        background-attachment: scroll;
        background-repeat: no-repeat;
        padding: 100px 0;
    }
    section.parallax2 {
        background: url('/images/2_schome.jpeg') 0 50%;
        background-size: cover;
        background-attachment: scroll;
        background-repeat: no-repeat;
        padding: 100px 0;
    }
    .area .area-list {
        width: 70%;
    }
    
    .secondaryhero {
        background-attachment: scroll !important;
    }
    .secondary-section .container {
        width: 90%;
    }
    .areas-of-service-hero {
        background: url('/images/areasofservice-small.jpg') right;
    }
    .building-services-hero {
        background: url('/images/buildingservices-small.jpg') right;
    }
    .partnerships-hero {
        background: url('/images/partnerships-small.jpg') center bottom;
    }
    .case-studies-hero {
        background: url('/images/casestudies-small.jpg') center bottom;
    }
    
    .hero h1 {
        font-size: 32px;
    }
    
    .service-bar .service-bar-list {
        width: 65%;
    }
    
    .parallax h1 {
        font-size: 32px;
    }
    
    .parallax2 h1 {
        font-size: 32px;
    }
    
    
    .secondaryhero h1 {
        font-size: 32px;
    }
    
    .hero p {
        width: 90%;
    }
    
    .hero .btn-primary {
        margin-top: 0;
        margin-bottom: 0;
        padding: 5px 25px;
    }
    #contactmap {
        height: 400px;
    }

}
